




$(window).on('load',function(){$('.preloader').fadeOut();});

var cooldown = 110;
    for(let i = 0; i < $(".accordeon").children().length; i++){ 
        setTimeout(function(){
            $(`.accordeon>.slice:eq(${i})`).css("animation", "SliceHeight 1s  infinite linear");
    }, cooldown*i);
}

//Get the button:
mybutton = document.getElementById("myBtn");

// When the user scrolls down 20px from the top of the document, show the button
window.onscroll = function() {scrollFunction()};

function scrollFunction() {
  if (document.body.scrollTop > 1000 || document.documentElement.scrollTop > 1000) {
    mybutton.style.opacity = "1";
  } else {
    mybutton.style.opacity = "0";
  }
}

$('.clock-hero').countdown('2022/10/10 09:59:56')
.on('update.countdown', function(event) {
  var format = '%H h : %M m : %S s';
  $(this).html(event.strftime(format));
});

$('.clock-auctions1').countdown('2022/10/10 20:59:56')
.on('update.countdown', function(event) {
  var format = '%H h : %M m : %S s';
  $(this).html(event.strftime(format));
});
$('.clock-auctions2').countdown('2022/10/10 10:09:06')
.on('update.countdown', function(event) {
  var format = '%H h : %M m : %S s';
  $(this).html(event.strftime(format));
});
$('.clock-auctions3').countdown('2022/10/10 01:34:12')
.on('update.countdown', function(event) {
  var format = '%H h : %M m : %S s';
  $(this).html(event.strftime(format));
});
$('.clock-auctions4').countdown('2022/10/10 05:14:27')
.on('update.countdown', function(event) {
  var format = '%H h : %M m : %S s';
  $(this).html(event.strftime(format));
});
$('.clock-auctions5').countdown('2022/10/10 07:25:11')
.on('update.countdown', function(event) {
  var format = '%H h : %M m : %S s';
  $(this).html(event.strftime(format));
});
$('.clock-auctions6').countdown('2022/10/10 01:34:12')
.on('update.countdown', function(event) {
  var format = '%H h : %M m : %S s';
  $(this).html(event.strftime(format));
});
$('.clock-auctions7').countdown('2022/10/10 10:39:01')
.on('update.countdown', function(event) {
  var format = '%H h : %M m : %S s';
  $(this).html(event.strftime(format));
});
$('.clock-auctions8').countdown('2022/10/10 09:14:50')
.on('update.countdown', function(event) {
  var format = '%H h : %M m : %S s';
  $(this).html(event.strftime(format));
});

$(document).ready(function() {
    $('.play, .popup-youtube, .popup-vimeo, .popup-gmaps').magnificPopup({
      type: 'iframe',
      mainClass: 'mfp-fade',
      removalDelay: 160,
      preloader: true,

      fixedContentPos: true
    });
});











